<template>
  <div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <div class="_Select">
          <span style="width: 60px">公司：</span>
          <el-select
            v-model="value1"
            clearable
            placeholder="请选择"
            filterable
            @change="compyInp"
          >
            <el-option
              v-for="item in compyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span style="width: 60px">部门：</span>
          <el-select
            v-model="value2"
            clearable
            placeholder="请选择"
            filterable
            @change="deptmenInp"
          >
            <el-option
              v-for="item in option2"
              :key="item.id"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span style="width: 60px">岗位：</span>
          <el-select
            v-model="value3"
            @clear="getFunList"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in option3"
              :key="item.id"
              :label="item.postName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button icon="el-icon-search" @click="searchList"></el-button>
        <el-button class="add" type="primary" @click="addJobFun"
          >修改职能</el-button
        >
      </div>
      <div class="_TableBox">
        <el-table
          :data="
            JobFunList.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          "
          style="width: 100%"
          :height="elementHeight"
          :stripe="true"
          ref="tb"
          @selection-change="handleSelectionChange"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
        >
          <!-- <el-table-column type="selection" width="80"></el-table-column> -->
          <el-table-column type="selection" width="80">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="postName" label="岗位名称" width="180">
          </el-table-column>
          <el-table-column prop="functionName" label="职能名称">
          </el-table-column>
          <el-table-column prop="saveTime" label="保存日期"> 
            <template slot-scope="scope">
              {{ scope.row.saveTime?scope.row.saveTime.substring(0, 10):''}}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <i class="el-icon-delete" @click="deleteJobFun(scope.row)"
                  >删除</i
                >
              </template>
            </el-table-column> -->
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="JobFunList.length"
          class="_Pagination"
        >
        </el-pagination>
      </div>
    </div>
    <Dialog
      title="添加岗位职能"
      :showTowText="false"
      width="25%"
      top="25vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div class="_Select">
        <span>岗位名称</span>
        <!-- <el-select v-model="value3" placeholder="请选择" v-if="option4">
          <el-option
            v-for="item in option4"
            :key="item.postId"
            :label="item.postName"
            :value="item.postId"
          >
          </el-option>
        </el-select> -->
        <el-select v-model="value3" placeholder="请选择">
          <el-option
            v-for="item in option3"
            :key="item.id"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span>职能名称</span>
        <el-select
          v-model="value4"
          placeholder="请选择"
          filterable
          multiple
          collapse-tags
          @change="handleSelect"
        >
          <el-option
            v-for="item in functionList"
            :key="item.id"
            :label="item.functionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, put } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      isSerach: false,
      showDialog: false,
      JobFunList: [],
      // 下拉框
      compyList: [],
      deptList: [],
      jobList: [],
      functionList: [],
      option2: [],
      option3: [],
      option4: [],
      Company1: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      // 分页
      currentPage: 1,
      pageSize: 20,
      checkedGh: [],
      tableRowIndex: null,
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  created(){
    this.value1 = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    get("/api/Department/GetNoTree?CompId=" + this.value1).then((res) => {
      this.option2 = res.data;
      this.value2 = res.data[0].id;
    }).then(() =>{
      get("/api/Post?CompId=" + this.value1 + "&DeptId=" + this.value2).then((res) => {
        this.option3 = res.data;
        this.value3 = res.data[0].id;
        get("/api/PostAndFunction/GetPostAndFunction?PostId=" + this.value3).then(res =>{
          this.JobFunList = res.data;
        })
      });
    })
  },
  mounted() {
    this.compyList = this.$store.state.companyList;
    this.getDeptAll();
    this.getFunList();
    // this.getJobFunAll();
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 70 + 60);
      });
    },
    renderTime(date) {
      var time = new Date(date).toJSON();
      return new Date(+new Date(time) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    rowClick(row) {
      this.checkedGh = row;
      this.tableRowIndex = row.index;
      this.JobFunList.map((item) => {
        item.checked = false;
        if (row.id == item.id) {
          row.checked = true;
        }
      });
    },
    handleSelectionChange(selection){
      console.log(selection);
      // this.checkedGh = selection[0];
      // if (selection.length > 1) {
      //   this.$refs.tb.clearSelection();
      //   this.$refs.tb.toggleRowSelection(selection.pop());
      // }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleClose() {
      this.showDialog = false;
      this.checkedGh = [];
      // this.value3 = '';
    },
    handleReqData() {
      if (this.value4.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择",
        });
        return;
      }
      this.showDialog = false;
      this.addJobFunItem();
      this.checkedGh = [];
      this.value3 = '';
    },
    getDeptAll() {
      get("/api/Department/GetAllDept").then((resp) => {
        if (resp.code == 200) {
          this.deptList = resp.data;
        }
      });
    },
    getFunList() {
      get("/api/Function/GetList").then((resp) => {
        if (resp.code == 200) {
          this.functionList = resp.data;
        }
      });
    },
    // 筛选
    compyInp(e) {
      this.value2 = "";
      this.value3 = "";
      this.option3 = [];
      if (e) {
        this.Company1 = e;
        get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
          this.option2 = res.data;
        });
      } else {
        this.isSerach = false;
        this.getJobFunAll();
      }
    },
    deptmenInp(e) {
      this.deptmentName1 = e;
      this.value3 = "";
      get("/api/Post?CompId=" + this.value1 + "&DeptId=" + e).then((res) => {
        console.log(res);
        this.option3 = res.data;
      });
    },

    getJobListAll() {
      this.isSerach = false;
      // this.jobList = ''
      // this.value3 = ''
      // get('/api/Post?CompId=' + this.value1 + '&DeptId=' + this.value2).then(
      //   (resp) => {
      //     if (resp.code == 200) {
      //       this.jobList = resp.data
      //       this.value3 = resp.data[0].id
      //     }
      //   }
      // )
      this.searchList();
    },
    // 获取岗位职能信息
    searchList() {
      if (!this.value3) {
        this.$message({
          type: "warning",
          message: "请选择岗位",
        });
        return;
      }
      this.isSerach = true;
      this.getJobFunAll();
    },
    getJobFunAll() {
      var url1 =
        "/api/PostAndFunction/GetPostAndFunction?PostId=" + this.value3;
      var url2 = "/api/PostAndFunction/GetList";
      get(this.isSerach ? url1 : url2).then((resp) => {
        if (resp.code == 200) {
          if (this.showDialog) {
            resp.data.map((item) => {
              this.value4.push(item.functionId);
            });
          } else {
            resp.data.map((item) => {
              item.saveTime = this.renderTime(item.saveTime);
            });
            this.JobFunList = resp.data;
          }
        }
      });
    },

    // 添加职能
    handleSelect(val) {
      console.log(val);
      this.value4 = val;
    },
    addJobFun() {
      console.log(this.checkedGh);
      if(this.checkedGh.length == 0){
        if (this.value3 == "" || this.value3 == undefined) {
          this.$message({
            type: "warning",
            message: "岗位暂无信息，请先选择公司部门信息",
          });
          return;
        }
        this.getJobListAll();
        this.showDialog = true;
        this.value4 = [];
      }else{
        this.value4 = [];
        get('/api/Post/GetList').then(res =>{
          this.option3 = res.data
        })
        this.value3 = this.checkedGh.postId;
        this.getFunList();
        this.showDialog = true;
        this.value4.push(this.checkedGh.functionId);
      }
    },
    addJobFunItem() {
      var reqList = [];
      this.value4.map((item) => {
        reqList.push({
          Id: 0,
          FunctionId: item,
          PostId: this.value3,
          SaveTime: new Date().toISOString(),
          SaveUserId: this.$store.state.userInfo.id + "",
        });
      });
      put("/api/PostAndFunction?PostId=" + this.value3, {
        data: JSON.stringify(reqList),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.getList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("添加失败");
        });
    },
    deleteJobFun(row) {
      this.$confirm("您确认要删除" + row.functionName + "这个职能？")
        .then(() => {})
        .catch(() => {});
    },
    getList(){
      get("/api/PostAndFunction/GetList").then(res =>{
        if(res.code == 200){
          this.JobFunList = res.data;
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.dialog {
  ._Select {
    width: 90% !important;
    margin-top: 10px;
    span {
      padding-right: 10px;
    }
    .el-select {
      overflow: hidden !important;
    }
    /deep/ .el-select__tags {
      flex-wrap: nowrap !important;
      overflow: hidden;
    }
    
  }
}
.el-icon-delete {
  color: red;
}
.add {
  position: absolute;
  right: 20px;
}
</style>
